import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Container from 'react-bootstrap/Container'
import Header from "./header"
import MobileHeader from "./MobileHeader"
import Footer from './footer'
import Consult from './consult'

const Layout = ({ typePage, children, style, banner, noContainer=true, noCensus=false }) => {
  const [ width, setWidth ] = React.useState(false)

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  //全局设置页面文字大小
  React.useEffect(() => {
    if(typeof window !== "undefined") {
      let width = document.body.clientWidth
      if(width < 992) {
        setWidth(true)
      }else {
        setWidth(false)
      }
      window.addEventListener('resize', (event) => {
        setRootFontSize()

        let width = document.body.clientWidth
        if(width < 992) {
          setWidth(true)
        }else {
          setWidth(false)
        }

      })
    }
    setRootFontSize()
  }, [])

  const setRootFontSize = () => {
    let width = document.documentElement.clientWidth || document.body.clientWidth;
    if (width > 1200) {
      width = 1600;
    }
    let fontSize = (width / 100) >= 12 ? (width/100) : 12
    document.getElementsByTagName('html')[0].style['fontSize'] = fontSize + 'px'
  }

  const getHeader = (width) => {
    return typePage && !banner ? null : width ? <div style={width ? { display: 'block'} : {display: 'none'}}>
      <MobileHeader siteTitle={data.site.siteMetadata?.title || `Title`} />
    </div> : 
    <div style={width ? { display: 'none'} : {display: 'block'}}><Header siteTitle={data.site.siteMetadata?.title || `Title`} /></div>
  }

  return (
    <>
      { typePage || noCensus ? null : <Consult /> }
      {/* { typePage && !banner ? null : <Header siteTitle={data.site.siteMetadata?.title || `Title`} /> } */}
      { getHeader(width) }
      { noContainer ? <Container style={style}>
        {children}
      </Container> : children }
    </>
  )
}

export default Layout
