import { Link } from "gatsby"
import React from "react"
import "./common.css"
import './animate-index.css'
import { Form, Col, Row, Button, Modal } from 'react-bootstrap'
import { bdstatistics, apiURL } from './census'

const Consult = props => {
  const [ showWechet, setShowWechet ] = React.useState({ wechet: false, backTop: false, form: false })
  const [ source, setSource ] = React.useState(null)
  const [ validated, setValidated ] = React.useState(false)
  const [ validations, setVaildations ] = React.useState()
  let defaultValue = {
    phone: null,
    email: null
  }
  const [ inputData, setInputData ] = React.useState(defaultValue)

  

  React.useEffect(() => {
    if(typeof window !== "undefined") {
      window.addEventListener('scroll', (event) => {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        if(scrollTop > 800){
          setShowWechet({ ...showWechet, backTop: true })
        }else {
          setShowWechet({ ...showWechet, backTop: false })
        }
      })
      let pathname = window?.location?.pathname
      if(pathname.indexOf('/') == 0) pathname = pathname.substring(pathname.indexOf('/')+1) 
      if(pathname?.length == pathname.lastIndexOf('/')+1) pathname = pathname.substring(0, pathname.lastIndexOf('/'))
      setSource(pathname)

      if(window.location.href.indexOf('articlesDetail')>-1) {
        const consult = window.document.getElementsByClassName('consult-tip')
        const consultimg =window.document.getElementsByClassName('consult-img-bg') 
        if(consult?.length) {
          for(let i=0; i<consult?.length; i++) { consult[i].style.background = '#ffc458' }
          for(let i=0; i<consultimg?.length; i++) { consultimg[i].style.background = 'orange' }
        }
      } else {
        const consult = window.document.getElementsByClassName('consult-tip')
        const consultimg =window.document.getElementsByClassName('consult-img-bg') 
        if(consult?.length) {
          for(let i=0; i<consult?.length; i++) { consult[i].style.background = '#1885f8ad' }
          for(let i=0; i<consultimg?.length; i++) { consultimg[i].style.background = '#007bff' }
        }
      }
    }
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault(); // 阻止默认的提交的页面跳转行为
    event.stopPropagation();
    const [isValid, validation] = checkValidation()
    setVaildations(validation)
    setValidated(!isValid)

    if (isValid) {
      let newInputData = {
        ...inputData,
      }
 
      fetch(`${apiURL}/customer-infos`, {
        headers: { 'content-type': 'application/json' },
        method: "post",
        body: JSON.stringify(newInputData)
      })
        .then((res) => {
          if (res.status == 200) {
            // //设置cookie
            // document.cookie= `data=${JSON.stringify(inputData)}`
            // if(props.type == 'down') {
            //   props.changeModal(false)
            //   window.location.href= props.url
            // }
            // setShow(true)
          }
        })
        .catch(err => console.log(err))
    }
  }

  const checkValidation = () => {
    let validation = {};
    let regEmail = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/
    let reg = /^[1]([3-9])[0-9]{9}$/
    let regName = /^[a-zA-Z\u4e00-\u9fa5]+$/


    if (inputData.phone === null) {
      validation.phone = { status: false, msg: '手机号不能为空'}
      return [false, validation]
    } else if(!(reg.test(inputData.phone))) {
      validation.phone = { status: false, msg: '手机号输入不规范'}
      return [false, validation]
    }else {
      validation.phone = {status: true}
    }

    if (inputData.email === null) {
      validation.email = { status: false, msg: '邮箱不能为空' }
      return [false, validation]
    } else if(!(regEmail.test(inputData.email))) {
      validation.email = { status: false, msg: '邮箱输入不规范' }
      return [false, validation]
    } else {
      validation.email = { status: true }
    }

    return [true, validation]
  }

  const onInputChange = () => {

  }

  return (
    <div className="fixed-box">
      {/* <div onMouseOver={() => { setShowWechet({ ...showWechet, form: true }) }} 
          onMouseOut={() => {setShowWechet({ ...showWechet, form: false })}} style={{ display: 'flex', justifyContent: 'right', marginBottom: '0.625rem' }}>
        <div className="consult-tip consult-tip-top order-tip">
          <img src={require('./../images/编组5.png')} alt="" />
        </div>
        <div className={ showWechet?.form ? "checkForm form_animate_visible" : "checkForm formAnimateVisibleOut" }>
          <div className="order-title">订单查询</div>
          <Form className="bootstrap-form" validated={validated} onSubmit={handleSubmit} >
            <Form.Group >
              <Form.Control isInvalid={!validations?.order?.status}  required type="text" name="order"  placeholder="*订单号" onChange={onInputChange} />
              {
                !validations?.order?.status ?
                  <Form.Control.Feedback type="invalid">{validations?.order?.msg}</Form.Control.Feedback> 
                  : null
              }
            </Form.Group>
            <div className="sub-box">
              <Button type="submit" variant="primary" className="submit submit-btn">提交</Button>
            </div>
          </Form>
        </div>
      </div> */}
      {
        source == 'try' || source == 'price' || source == 'competition' ? null :  <div className="consult-tip">
        <div className="consult-img-bg">
            <img src={require('./../images/tiyan.png')} alt="" />
          </div>
          <Link className="consult-title" to="https://r8ja3mlg7i.feishu.cn/share/base/form/shrcn6Ns5Vk4fgiEanpunuN71Ve" target="_black">申请试用</Link>
        </div>
      }
     
      {/* <div style={{ width: '8.625rem', height: '2.875rem' }}></div> */}
      {/* <div className="consult-tip consult-tip-top zixun" onMouseOver={() => {setShowWechet({ ...showWechet, wechet: true })}} onMouseOut={() => {setShowWechet({ ...showWechet, wechet: false })}}>
        <div className="consult-img-bg">
          <img src={require('./../images/zixun.png')} alt="" />
        </div>
        <div className="consult-title" >产品咨询</div>
      </div> */}
      <div className="consult-tip consult-tip-top" style={{ visibility: showWechet.backTop ? 'inherit': 'hidden' }} onClick={() => { document.documentElement.scrollTop=0 }}>
        <span className="consult-img-bg">
          <img src={require('./../images/backtop.png')} alt="" />
        </span>
        <span className="consult-title" >返回顶部</span>
      </div>
      {/* <div className={ showWechet.wechet ? "wechet-modal animate_visible" : "wechet-modal animateVisibleOut" } style={{ right: -200 }}>
        <img src={require('./../images/consult.png')} style={{ width: '85%', height: '72%', position: 'absolute', top: '0.75rem' }}/>
        <div style={{ position: 'absolute', bottom: '0.4rem', fontSize: '0.875rem' }}>扫码联系我们</div>
      </div> */}
    </div>
  )
}

export default Consult


