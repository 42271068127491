import React from "react"
import { Nav, Navbar, Dropdown, Button, Row, Col, Table, Media, ListGroup } from 'react-bootstrap'
import { Link } from "gatsby"
import Container from 'react-bootstrap/Container'
import { bdstatistics, apiURL, mqStatistics } from './census'

const product = [ { title: '下载', id: 'down', href: '/airedu' }, 
  // { title: '市场', id: 'market', href: '/market' }, 
  // { title: '功能', id: 'function', href: '/product' } 
]
  // /helpcenter
const resources = [ 
  { title: '帮助文档', target: true, id: 'help', href: 'https://docs.airiot.cn/' }, 
  { title: 'API文档', target: true, id: 'word', href: 'https://airiot.apifox.cn/' }, 
  // { title: '应用案例', target: false, id: 'example', href: '/articles' },
  { title: '在线ppt', target: false, id: 'ppt', href: '/ppt' },
  { title: '版本列表', target: false, id: 'reposUpload', href: '/releases' }, 
  { title: '模块列表', target: false, id: 'repos', href: '/repos/front' },
  // { title: '阅读文档', target: false, id: 'helpcenter', href: '/helpcenter#driver' }
]

const company = [ { title: '关于我们', id: 'about', href: '/about' }, 
  { title: '联系我们', id: 'contact', href: '/about#connect' } ]
const find = [ { title: '最新活动', id: 'activity', href: 'https://appxow65u356815.pc.xiaoe-tech.com', target: true },
  { title: '活动回顾', id: 'industry', href: '/video4' },
  { title: '行业动态', id: 'industry', href: '/industryDynamics' } ]

const leftMedia = [ {
  titleImg: require('./../images/logoIcon.png'),
  descImg: 'AIRIOT',
  imgFlag: false,
  width: 14,
  height: 16,
  top: 7
  }
]

const Footer = (props, { siteTitle }) => {

  // React.useEffect(() => {
  //   bdstatistics()
  //   mqStatistics()
  // }, [])

  return (
  <div className="footer" style={{...props.style}}>
    <Row>
      <Col sm={3} style={{ padding: 0}}>
        {
          leftMedia.map((item, index) => {
            return (<Media className="footer-media-box" key={index}>
              <img src={require('./../images/logo.svg')} alt="" style={{ width: '32px' }} />
              <span className="footer-logo" style={ { backgroundImage: `url(${require("./../images/black.svg")})` }}></span>
            </Media>)
          })
        }

        <div>
          <div style={{ marginTop: '3rem', color: '#686A7E', fontSize: '0.875rem', fontWeight: 300, fontFamily: 'PingFangSC-Light, PingFang SC' }}>
            <p style={{ marginBottom: 5 }}>集团总部：北京市丰台区海鹰路6号院2号楼</p>
            <p style={{ marginBottom: 5 }}>产品咨询：<a style={{ color: '#686A7E',  fontWeight: 300, fontFamily: 'PingFangSC-Light, PingFang SC' }} href="tel:010-84860985">010-84860985</a></p>
            {/* <p style={{ marginBottom: 5 }}>BBS社区：<a style={{ color: '#686A7E',  fontWeight: 300, fontFamily: 'PingFangSC-Light, PingFang SC' }} href="http://bbs.airiot.link" target="_black">bbs.airiot.link</a></p> */}
          </div>
          <img style={{ margin: '0.25rem 0',  width: '5.8125rem', height: '5.8125rem' }} src={require('./../images/d6c58ec7-1cd7-4e1f-a0ca-dedf0a484128.jpeg')} alt=""/>
        </div>
        {/* <div className="footer-plat">低代码极速自主搭建物联网平台</div>
        <div className="footer-air">AIRIOT是先进技术引领下的物联网平台搭建工具。用户使用该平台，能快速构建稳定可靠的物联网系统，节省时间及人力成本。平台提供了丰富的功能库及组件库，支持二次开发，具有低成本、高效率、易操作，可扩展的特点</div> */}
        {/* <div className="footer-air">
          AIRIOT品牌隶属于航天科技控股集团股份有限公司智慧物联事业部(以下简称“航天科技”），是航天科技旗下物联网平台开发框架产品， 由航天科技智慧物联事业部历时10年倾力打造，致力于低代码搭建IOT平台，持续将业务抽象成通用的模块、组件、表单等，并经过项目应用实践沉淀和持续优化，形成了一款拥有完全自主知识产权、图形化开发、全面快速感知、快速构建不同行业应用、开放的物联网平台。
        </div> */}
      </Col>
      <Col sm={9} className="sm-col" style={{ padding: 0}}>
        <Row>
          <Col>
            <div className="footer-title">产品</div>
            <Nav className="flex-column">
              {
                product.map((item, index) => {
                  return <Link key={index} to={item.href}>{ item.title }</Link>
                })
              } 
            </Nav>
          </Col>
          <Col>
            <div className="footer-title">资源</div>
            <Nav className="flex-column">
              {
                resources.map((item, index) => {
                  return <Link key={index} to={item.href} target={ item.target ? '_black' : '_self' }>{ item.title }</Link>
                })
              } 
            </Nav>
          </Col>
          <Col>
            <div className="footer-title">发现</div>
            <Nav className="flex-column">
              {
                find.map((item, index) => {
                  return <Link key={index} to={item.href} target={ item.target ? '_black' : '_self' }>{ item.title }</Link>
                })
              } 
            </Nav>
          </Col>
          <Col>
            <div className="footer-title">公司</div>
            <Nav className="flex-column">
              {
                company.map((item, index) => {
                  return <Link key={index} to={item.href} target={ item.target ? '_black' : '_self' }>{ item.title }</Link>
                })
              } 
            </Nav> 
          </Col>
        </Row>
      </Col>
    </Row>
    {/* <div>
      <div style={{ color: '#686A7E', fontSize: '0.75rem', fontWeight: 300, fontFamily: 'PingFangSC-Light, PingFang SC' }}>
        <p style={{ marginBottom: 0 }}>关注我们</p>
        <p style={{ marginBottom: 0 }}>微信号：AIRIOT小助理</p>
      </div>
      <img style={{ margin: '0.25rem 0',  width: '3.8125rem', height: '3.8125rem' }} src={require('./../images/d6c58ec7-1cd7-4e1f-a0ca-dedf0a484128.jpeg')} alt=""/>
      <div style={{ color: '#686A7E', fontSize: '0.75rem', fontWeight: 300, fontFamily: 'PingFangSC-Light, PingFang SC' }}>
        <p style={{ marginBottom: 0 }}>联系我们：<a style={{ color: '#686A7E', fontSize: '0.75rem', fontWeight: 300, fontFamily: 'PingFangSC-Light, PingFang SC' }} href="tel:010-84860985">010-84860985</a></p>
      </div>
    </div> */}
    {/* <div className="footer-br"></div>
    <div className="footer-message">
      <img src={require('./../images/footer3.png')} alt=""/>
      <a href="tel:16601156507" style={{ color: '#373E98', marginLeft: '0.5rem' }}>杜经理：16601156507</a>
      <a href="tel:13701389670" style={{ color: '#373E98', marginLeft: '0.5rem' }}>丁经理：13701389670</a>
      <a href="tel:18810558199" style={{ color: '#373E98', marginLeft: '0.5rem' }}>王经理：18810558199</a>
    </div> */}

    <div className="footer-br"></div>
    <div className="footer-message">
      <a href="https://beian.miit.gov.cn/" target="_black" style={{ color: '#373E98', marginLeft: '0.5rem' }}>京ICP备19031105号-2</a>
    </div>
  </div>
)
}

export default Footer
